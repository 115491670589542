#floor-plans {
  padding-bottom: 19.896vw;
  overflow: hidden;
  @media (min-width: $size-xs) {
    background-image: url('../images/entering-artwork.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 65vw;
  }
  .virtual-plan-content {
    padding-top: 9.74vw;

    @media (max-width: $size-xs) {
      padding-top: 0;
      margin-bottom: 0;
      text-align: center;
    }
  }
  .virtual-plan-wrapper {
    position: relative;
    height: 100%;
    width: 110%;
    @media (max-width: $size-sm) {
      width: 100%;
    }
    &:before {
      content: '';
      background-image: url('../images/virtual-plan-artwork.svg');
      width: 11.51vw;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 51%;
      left: 1%;
      z-index: 10;
      @media (max-width: $size-sm) {
        left: -10%;
      }
    }
    .virtual-plan {
      position: absolute;
      border: 1.458vw solid #fafafa;
      left: 13%;
      border-radius: 4.063vw;
      @media (max-width: $size-sm) {
        position: relative;
        left: auto;
      }
      @media (min-width: $container-width) {
        border-width: 28px;
        border-radius: 78px;
      }
    }
  }

  .entering-info {
    padding-top: 15vw;
    @media (max-width: $size-xs) {
      padding-top: 0;
    }
  }
  .entering-info-intro {
    margin-bottom: $spacer * 3;
    p {
      padding-right: 11vw;
    }
    h2 {
      padding-right: 2.5vw;
    }
  }
  #tips {
    counter-reset: tips;
  }
  .checkboxes-list {
    list-style: none;
    padding: 0;
    position: relative;
    margin: 0;

    li {
      padding-left: 4.5vw;
      @media (max-width: $size-sm) {
        padding-left: 65px;
        font-size: 20px;
      }
      &:before {
        position: absolute;
        left: 0;
        counter-increment: tips;
        content: counter(tips, decimal-leading-zero);
        font-size: 2.865vw;
        font-weight: $font-black;
        line-height: 100%;
        margin-top: -3px;

        @media (min-width: $container-width) {
          font-size: 55px;
        }
        @media (max-width: $size-md) {
          font-size: 3.5vw;
        }
        @media (max-width: $size-sm) {
          font-size: 40px;
          margin-top: 0;
        }
      }
      &:not(:last-child) {
        margin-bottom: $spacer * 2.5;
      }
      strong {
        display: block;
      }
    }
  }
}


