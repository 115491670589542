$modal-padding: 4vw;
$modal-icon-size: 3.542vw;
.modal-backdrop {
  background-color: rgba(#000, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.modal {
  background-color: $modal-backgorund-color;
  max-width: 80%;
  max-height: 90%;
  border: 2px solid white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  .modal-content {
    position: relative;
    overflow: auto;
    height: 100%;
    padding: $modal-padding;
  }
  .close {
    position: absolute;
    left: -$modal-icon-size/2;
    top: -$modal-icon-size/2;
    width: $modal-icon-size;
    height: $modal-icon-size;
    background-image: url('../images/close.svg');
    background-position: center;
    background-size: contain;
    cursor: pointer;
    font-size: 0;
    @media (max-width: $size-xs) {
      $modal-icon-size: 36px;
      width: $modal-icon-size;
      height: $modal-icon-size;
      left: -$modal-icon-size/2;
      top: -$modal-icon-size/2;
    }
  }
  .video,
  video {
    height: 100%;
  }
  .video {
    margin: -$modal-padding;
    height: calc(100% + (#{$modal-padding} * 2));
  }
  &--full {
    max-width: calc(100% - #{$modal-icon-size});
    max-height: calc(100% - #{$modal-icon-size});
    .modal-content {
      padding: 0;
      border-radius: 10px;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
}
