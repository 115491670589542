@import url('https://use.typekit.net/lun7xxl.css');
@import 'variables';
@import 'typography';
@import 'buttons';
@import 'helpers';
@import 'grid';
@import 'header';
@import 'home';
@import 'about';
@import 'capture-the-event';
@import 'crowdicity';
@import 'speakers';
@import 'floor-plans';
@import 'modal';
@import 'footer';
@import 'reset';
html {
  scroll-behavior: smooth;
}
body {
  background-color: $body-background-color;
  color: $body-text-color;
  overflow-x: hidden;

  a {
    color: $link-color;
  }
  &.scroll-block {
    overflow: hidden;
  }
}
.wrapper {
  @media (min-width: $size-xs) {
    background-image: url('../images/artboard.svg');
    background-repeat: repeat-y;
    background-position: top right;
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacer;
  padding-right: $spacer;
  max-width: 85.417vw;
  @media (max-width: $size-xs) {
    max-width: 89.13vw;
  }
}
hr {
  border: 0;
  height: 1px;
  width: 100%;
  background: rgba(white, 0.24);
  margin: 6.042vw 0;
  @media (min-width: $container-width) {
    margin: ($spacer * 6) 0;
  }
  @media (max-width: $size-xs) {
    margin: 10vw 0;
  }
}
.video {
  position: relative;
  .play-video {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    cursor: pointer;
    background-image: url('../images/play.svg');
    background-position: center;
    background-size: contain;
    transition: transform 0.3s;
    width: 7.552vw;
    height: 7.552vw;
    animation: pulse 1.5s infinite;
    @media (min-width: $container-width) {
      width: 145px;
      height: 145px;
    }
    @media (max-width: $size-xs) {
      display: none;
    }
    &:hover {
      animation-play-state: paused;
    }
  }
  video {
    width: 100%;
    display: block;
    border-radius: 10px;
    outline: 0;
  }
}

// Animations
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.auth-error-message{
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1{
    color: $orange;
  }
}
