.page-footer {
  background-color: $footer-background-color;
  padding-top: $spacer;
  padding-bottom: $spacer;
  &,
  a {
    color: $footer-color;
  }
  .column {
    @media (max-width: $size-xs) {
      margin-bottom: 0;
      &:first-child {
        order: 0;
        padding: 0;
      }
      &:nth-child(2) {
        order: 2;
        padding: 0;
      }
      &:nth-child(3) {
        order: 1;
        text-align: left;
      }
    }
  }
  .copyright {
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
    @media (max-width: $size-xs) {
      text-align: left;
    }
  }
  .footer-logo {
    max-width: 9.479vw;
    @media (max-width: $size-xs) {
      max-width: 80px;
    }
  }
  .ciena-logo {
    max-width: 5.781vw;
    @media (max-width: $size-xs) {
      max-width: 55px;
    }
  }
}
