// Colors
$orange: #f07f13;
$green: #2fb08f;
$body-background-color: #1b1f2c;
$body-text-color: #fff;
$footer-background-color: #151720;
$footer-color: #fff;
$link-color: #fff;
$modal-backgorund-color: #1b1f2c;

// Font
$font-sans-serif: aktiv-grotesk, sans-serif;
$font-light: 300;
$font-bold: 700;
$font-medium: 500;
$font-x-bold: 800;
$font-black: 900;

// Responsive breakpoints
$size-xs: 567px;
$size-sm: 812px;
$size-md: 1024px;
$size-lg: 1440px;
$size-xl: 1640px;
$size-xxl: 1940px;

// Sizes
$spacer: 16px;
$container-width: 1920px;
$section-spacing-xs: $spacer * 3;