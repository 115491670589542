#crowdicity {
  padding-top: 7.292vw;
  margin-bottom: 9.692vw;
  @media (max-width: $size-xs) {
    padding-top: $section-spacing-xs;
    margin-bottom: 0;
    text-align: center;
  }
  .visit-crowdicity {
    @extend .btn-gradient-primary;
  }

  .keynote {
    display: flex;
    align-items: center;
    margin-bottom: 3.177vw;
    @media (max-width: $size-xs) {
      display: block;
      text-align: center;
    }
    .icon {
      max-width: 7.917vw;
      margin-right: $spacer * 1.2;
      @media (max-width: $size-xs) {
        max-width: 79px;
        margin-bottom: $spacer;
      }
    }
    h3 {
      margin-bottom: $spacer;
      font-size: 1.875vw;
      @media (min-width: $container-width) {
        font-size: 36px;
      }
      @media (max-width: $size-md) {
        font-size: 25px;
      }
      @media (max-width: $size-xs) {
        font-size: 18px;
      }
    }
    p {
      font-size: 18px;
      font-weight: $font-light;
      @media (max-width: $size-xs) {
        font-size: 16px;
      }
    }
    &:not(:last-child) {
      margin-bottom: $spacer * 2;
    }
  }
  .video-section {
    padding-top: 5vw;
    padding-bottom: 7.656vw;
    @media (max-width: $size-sm) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.crowdicity-separator {
  position: relative;
  margin-top: 5vw;
  @media (max-width: $size-sm) {
    margin-top: 10vw;
  }
  .has-hr {
    width: 83%;
    @media (max-width: $size-xs) {
      width: 100%;
    }
    &:after {
      width: 55%;
    }
  }
  .drone-artwork {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Crowdicity Widget
.crowdicity-widget {
  border: 23px solid #fafafa;
  width: 100%;
  border-radius: 81px;
  height: 806px;
  max-width: 617px;
  @media (max-width: $size-lg) {
    height: 700px;
  }
  @media (max-width: $size-md) {
    height: 675px;
  }
  @media (max-width: $size-sm) {
    height: 640px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $size-xs) {
    border-width: 16px;
    border-radius: 40px;
    height: 400px;
    text-align: left;
  }
  .cwd-widget-container {
    $accent-color: #278ace;
    height: 100%;
    border: 3px solid $accent-color;
    border-radius: 57.7px;
    background-color: white;
    @media (max-width: $size-xs) {
      border-radius: 20px;
    }
    .widget-header {
      padding: $spacer * 2;
      height: 82px;
      @media (max-width: $size-xs) {
        padding: $spacer;
        height: 60px;
      }
      .title {
        font-size: 22px;
        color: $accent-color;
        font-weight: $font-bold;
        @media (max-width: $size-xs) {
          font-size: 12px;
        }
      }
      #widget-refresh-button {
        color: $accent-color;
        font-size: 20px;
        @media (max-width: $size-xs) {
          font-size: 18px;
        }
      }
      .spacer {
        margin: 0;
      }
    }
    .widget-listing-area {
      height: calc(100% - 82px);
      @media (max-width: $size-xs) {
        height: calc(100% - 60px);
      }
    }
    .activity-list-text {
      color: #606060;
    }
    .widget-listing {
      max-height: 100%;
      padding: $spacer * 2;
      height: 100%;
      @media (max-width: $size-xs) {
        padding: $spacer;
      }
      li {
        padding: $spacer 0;
        display: flex;
        align-items: center;
        line-height: 1.2;
        @media (max-width: $size-xs) {
          padding: $spacer/2 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
      .votes_box,
      .idea-title {
        top: 0;
      }
      .idea-title {
        width: 100%;
        @media (max-width: $size-xs) {
          font-size: 12px;
        }
      }
      .postedin {
        @media (max-width: $size-xs) {
          font-size: 14px;
        }
      }
      .votes_box {
        .counter {
          @media (max-width: $size-xs) {
            font-size: 16px;
            line-height: 1.5;
          }
        }
        .text {
          @media (max-width: $size-xs) {
            font-size: 10px;
          }
        }
      }
    }
  }
}
