body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
strong {
  font-weight: $font-bold;
}
img {
  vertical-align: middle;
  max-width: 100%;
}
a {
  text-decoration: none;
}
@for $i from 1 through 6 {
  h#{7 - $i} {
    margin: 0;
  }
}
p {
  margin: 0;
}
