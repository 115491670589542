// breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
  @if $mq2 == false {
    @media ($maxmin: $mq01) {
      @content;
    }
  } @else {
    @media (min-width: $mq01) and (max-width: $mq2) {
      @content;
    }
  }
}

// responsive font size mixin
@mixin font-size-map($font-size-map) {
  @each $breakpoint, $font-size in $font-size-map {
    @if $breakpoint == null {
      font-size: $font-size;
    } @else {
      @include breakpoint($breakpoint) {
        font-size: $font-size;
      }
    }
  }
}

// Font sizes
$font-size-xl: (
  null: 70px,
  $size-xxl: 3.646vw,
  $size-xs: 30px,
);
$font-size-lg: (
  null: 31px,
  $size-xs: 28px,
);
$font-size-md: (
  null: 26px,
  $size-xs: 20px,
);
$font-size-base: (
  null: 20px,
  $size-xs: 16px,
);
$font-size-sm: (
  null: 18px,
);
$font-size-xs: (
  null: 15px,
);
body {
  font-family: $font-sans-serif;
  @include font-size-map($font-size-base);
  font-weight: $font-light;
  line-height: 1.3;
}
h1,
h2 {
  @include font-size-map($font-size-xl);
  font-weight: $font-black;
}
h3 {
  @include font-size-map($font-size-lg);
}
h4 {
  @include font-size-map($font-size-md);
}
.text-md {
  @include font-size-map($font-size-md);
}
@for $i from 1 through 6 {
  h#{$i}:not(:last-child) {
    margin-bottom: $spacer * 2;
    @media (max-width: $size-xs) {
      margin-bottom: $spacer * 1.2;
    }
  }
}
p:not(:last-child) {
  margin-bottom: $spacer * 2;
  @media (max-width: $size-xs) {
    margin-bottom: $spacer * 1.2;
  }
}
.label {
  @include font-size-map($font-size-sm);
  font-weight: $font-bold;
  text-transform: uppercase;
}
