#about {
  position: relative;
  @media (max-width: $size-xs) {
    padding-top: $section-spacing-xs;
  }
  h2 {
    @media (min-width: $size-xs) {
      font-size: 2.708vw;
    }
    @media (min-width: $container-width) {
      font-size: 52px;
    }
  }
  .digital-keynotes {
    margin-top: -$spacer;
    @media (max-width: $size-sm) {
      margin-top: 0;
    }
    .keynote {
      margin-bottom: 0;
      font-weight: $font-black;
      text-transform: uppercase;
      font-size: 3.646vw;
      line-height: 1.2;
      @media (min-width: $container-width) {
        font-size: 70px;
      }
    }
  }
  .video-wrapper {
    @media (max-width: $size-xs) {
      margin-bottom: 0;
    }
  }
  .video {
    position: relative;
    @media (min-width: $size-xs) {
      &:after,
      &:before {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
        height: 100%;
        z-index: 5;
        pointer-events: none;
      }
      &:before {
        background-image: url('../images/crowdicity-video-artwork-2.svg');
        width: 6vw;
        left: -4.8vw;
        top: 6%;
      }
    }

    video {
      border: 2px solid white;
      @media (max-width: $size-xs) {
        border-width: 1px;
      }
    }
  }
}
