#speakers {
  background: linear-gradient(45deg, #f47f0c 0%, #d31924 280%);
  padding-bottom: 4vw;
  padding-top: 7vw;
  position: relative;
  @media (max-width: $size-xs) {
    padding-top: $section-spacing-xs;
    padding-bottom: $section-spacing-xs;
    text-align: center;
  }
  &:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/speaker-pattern.svg');
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100%;
    background-position: top center;
    @media (max-width: $size-xs) {
      background-size: 350%;
    }
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .speakers-intro {
    @media (min-width: $size-xs) {
      margin-bottom: $spacer * 3;
      h2 {
        margin-bottom: $spacer * 2;
      }
    }
  }
  .speaker-artwork {
    margin-top: -16vw;
  }
  .speaker-label,
  input {
    display: none;
  }
  .speaker {
    margin-bottom: $spacer * 3;

    @media (max-width: $size-xs) {
      margin-bottom: 0;
    }
    &.hidden {
      display: none;
    }
  }
  .speaker-image {
    border-radius: 10px;

    @media (max-width: $size-xs) {
      max-width: 125px;
    }
  }
  .speaker-image,
  .speaker-name {
    margin-bottom: $spacer;
  }
  .speaker-name,
  .speaker-description {
    text-align: center;
    @media (max-width: $size-xs) {
      margin-bottom: 0;
    }
  }
  .speaker-name {
    @media (max-width: $size-xs) {
      margin-bottom: $spacer/2;
    }
  }

  .speaker-description {
    @include font-size-map($font-size-sm);
  }
  .speaker-content {
    @media (max-width: $size-xs) {
      padding-bottom: $spacer * 2;
      margin-top: $spacer * 2;
      text-align: center;
      border-bottom: 1px solid white;
    }
  }
  .has-hr {
    @media (max-width: $size-xs) {
      margin-top: $spacer * 2;
    }
  }
  .load-more {
    @extend .btn-secondary;
  }

  // mobile Accordion
  @media (max-width: $size-xs) {
    .speaker {
      &:first-child .speaker-label {
        border-top: 1px solid white;
      }
      // &:last-child .speaker-label {
      //   border-bottom: 1px solid white;
      // }
    }
    .speaker-label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: $font-bold;
      border-bottom: 1px solid white;
      padding: $spacer;

      &:after {
        content: '';
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        margin-left: $spacer;
        position: absolute;
        right: 9vw;
      }
    }
    .speaker-content {
      display: none;
    }
    input:checked {
      + .speaker-label {
        &:after {
          transform: rotate(-135deg);
        }
      }
      ~ .speaker-content {
        display: block;
      }
    }
  }
}
