#home {
  background-image: url(../images/home.jpg);
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  @media (max-width: $size-xs) {
    background-image: url(../images/home-xs.jpg);
    height: 100vh;
  }
  .container {
    @extend .container;
    height: 56.25vw;
    display: flex;
    align-items: flex-end;
    @media (max-width: $size-xs) {
      height: 100%;
    }
  }
  .home-event {
    padding-left: 4.635vw;
    padding-right: 3.75vw;
    width: 100%;
    margin-bottom: 8vw;
    @media (max-width: $size-xs) {
      padding-left: 2vw;
      padding-right: 2vw;
      margin-bottom: 10vw;
    }
    &--logo {
      margin-bottom: 0;
      img {
        max-width: 32.708vw;
        @media (max-width: $size-sm) {
          max-width: 270px;
        }
        @media (max-width: $size-xs) {
          max-width: 65.217vw;
        }
      }
    }
    &--date {
      font-size: 28px;
      margin-top: -$spacer;
      margin-bottom: 4vw;
      @media (max-width: $size-sm) {
        font-size: 20px;
        margin-bottom: 3vw;
        margin-top: -$spacer/2;
      }
      @media (max-width: $size-xs) {
        font-size: 4.348vw;
        margin-bottom: 10vw;
        margin-top: 0;
      }
    }
  }
  .countdown-title {
    margin-bottom: 0;
    text-transform: uppercase;
    @media (max-width: $size-xs) {
      text-transform: none;
    }
  }
  .countdown {
    margin-bottom: $spacer * 2;
    @media (max-width: $size-xs) {
      margin-bottom: $spacer;
    }
    > span {
      font-size: 5.208vw;
      font-weight: $font-x-bold;
      display: inline-block;
      line-height: 1.2;
      @media (max-width: $size-xs) {
        font-size: 30px;
      }
      @media (min-width: $container-width) {
        font-size: 100px;
      }
      & + span {
        margin-left: 3vw;
      }
    }
    small {
      font-weight: $font-light;
      font-size: 20px;
      display: block;
      text-align: right;
      @media (max-width: $size-xs) {
        font-size: 16px;
      }
    }

    > .post-launch {
      font-size: 3.5vw;
      font-weight: $font-x-bold;
      display: inline-block;
      line-height: 1.2;
      @media (max-width: $size-xs) {
        font-size: 24px;
      }
    }
  }
  .ciena-logo {
    text-align: right;
    @media (max-width: $size-xs) {
      margin-top: 15vw;
    }
    img {
      max-width: 5.781vw;
      @media (max-width: $size-xs) {
        max-width: 15.459vw;
      }
    }
  }
  .goDown {
    width: 2.865vw;
    height: 2.865vw;
    display: inline-block;
    background-image: url(../images/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: $spacer;
    background-size: contain;
    @media (min-width: $container-width) {
      width: 55px;
      height: 55px;
    }
    @media (max-width: $size-sm) {
      bottom: $spacer/2;
    }
    @media (max-width: $size-xs) {
      width: 30px;
      height: 30px;
      bottom: $spacer;
    }
  }
}
