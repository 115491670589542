%btn {
  appearance: none;
  display: inline-block;
  line-height: 1;
  border: 2px solid transparent;
  padding: ($spacer/1.3) ($spacer * 2.6);
  border-radius: 53px;
  font-size: 20px;
  text-align: center;
}

%btn-gradient {
  appearance: none;
  line-height: 1;
  border-radius: 29px;
  display: inline-block;
  padding: 2px;
  overflow: hidden;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: $size-xs) {
    width: 100%;
  }
  span {
    display: block;
    background-color: $body-background-color;
    padding: $spacer/1.3 4vw;
    border-radius: 29px;
    transition: background-color 0.2s;
    z-index: 2;
    position: relative;
    pointer-events: none;
  }
  &:hover span,
  &.active span {
    background-color: transparent;
  }
}

.btn-primary {
  @extend %btn;
  border-color: $orange;
  transition: background-color 0.3s;
  &:hover,
  &.active {
    background-color: $orange;
  }
}
.btn-secondary {
  @extend %btn;
  border-color: white;
  transition: background-color 0.3s;
  &:hover,
  &.active {
    background-color: white;
    color: $body-background-color;
  }
}

.btn-gradient-primary {
  @extend %btn-gradient;
  background: transparent linear-gradient(262deg, #ee7d13 0%, #d21a23 100%) 0%
    0% no-repeat padding-box;
}
.btn-gradient-secondary {
  @extend %btn-gradient;

  background: transparent linear-gradient(262deg, #355c9f 0%, #2fac90 100%) 0%
    0% no-repeat padding-box;
}
.has-hr {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0 !important;
  @media (min-width: $size-xs) {
    &:before,
    &:after {
      content: '';
      height: 1px;
      width: 100%;
      display: block;
      background: rgba(white, 0.24);
    }
    &:before {
      margin-right: 2vw;
    }
    &:after {
      margin-left: 2vw;
    }
    * {
      flex-shrink: 0;
    }
  }
}
