.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-orange {
  color: $orange;
}
.text-green {
  color: $green;
}
