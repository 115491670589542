.capture-the-event {
  padding-bottom: 6vw;
  @media (max-width: $size-xs) {
    padding-bottom: $section-spacing-xs;
  }
  @media (min-width: $size-xs) {
    hr {
      margin-bottom: $spacer * 4;
      margin-top: $spacer * 4;
    }
  }

  .event-artwork {
    position: absolute;
    margin-top: -8vw;
    max-width: 14.167vw;
  }
  .agenda-info {
    max-width: 75%;
    @media (max-width: $size-sm) {
      max-width: 100%;
    }
  }
  .tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: $spacer * 1.5;
    @media (max-width: $size-xs) {
      display: block;
      .tab-item {
        margin-bottom: $spacer/2;
      }
    }
    @media (min-width: $size-xs) {
      .tab-item + .tab-item {
        margin-left: $spacer * 1.5;
      }
    }

    a {
      @extend .btn-gradient-primary;
    }
  }
  .filters {
    @media (max-width: $size-xs) {
      display: flex;
      justify-content: space-between;
    }
    .title,
    .main-title {
      @extend .label;
    }
    .main-title {
      display: flex;
      align-items: center;
      margin-bottom: $spacer * 1.5;
      &:after {
        content: '';
        height: 1px;
        width: 100%;
        display: block;
        flex: 1;
        background-color: rgba(white, 0.24);
        margin-left: $spacer * 1.3;
      }
    }
    .title {
      min-width: 80px;
    }

    .session-filter,
    .topic-filter {
      display: flex;
      @media (max-width: $size-xs) {
        width: 45%;
      }
    }
    .filtering-options a {
      margin-left: $spacer;
      margin-bottom: $spacer;
      text-transform: uppercase;

      @media (max-width: $size-xs) {
        display: none !important;
      }
    }
    .session-filter .filtering-options a,
    .topic-filter .filtering-options a {
      border-width: 1px;
      font-size: 16px;
      padding: ($spacer/1.5) ($spacer * 1.5);
    }
    .session-filter .filtering-options a {
      @extend .btn-primary;
    }
    .topic-filter .filtering-options a {
      @extend .btn-secondary;
    }
    @media (max-width: $size-xs) {
      .has-hr {
        display: none;
      }
    }
  }
  .tab {
    display: none;
    &.active {
      display: block;
    }
  }
  .topics-wrapper {
    margin-top: $spacer * 2;
    @media (min-width: $size-xs) {
      .has-hr {
        width: calc(100% - 30% + 2vw);
        &:after {
          width: 30%;
        }
      }
    }
  }
  .topic {
    position: relative;
    margin-bottom: $spacer * 2;
    display: flex;
    flex-direction: column;

    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    figure {
      margin: 0;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: $spacer/1.5;
      height: 188px;
    }
    figcaption {
      position: absolute;
      font-weight: $font-light;
      padding: 0 $spacer * 2;
      word-break: break-word;
      font-size: 23px;
      max-height: 95%;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: $size-xs) {
        font-size: 20px;
      }
    }

    .date {
      @include font-size-map($font-size-sm);
      font-weight: $font-bold;
      text-transform: uppercase;
      margin-bottom: $spacer/1.5;
    }
    .description:not(:last-child) {
      margin-bottom: $spacer/1.5;
    }
    .learn-more {
      @extend .btn-secondary;
      font-size: 18px;
      padding: $spacer/2 $spacer;
      font-weight: $font-bold;
      align-self: flex-start;
      margin-top: auto;
    }
    &.added-to-calendar {
      figure:after {
        content: '';
        background-image: url(../images/checked.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        position: absolute;
        right: $spacer;
        top: $spacer;
      }
    }
  }
  .custom-select {
    display: none;
  }
  @media (max-width: $size-xs) {
    .custom-select {
      position: relative;
      display: block;

      &:after {
        content: '';
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        transform: rotate(45deg) translateX(-50%);
        position: absolute;
        right: $spacer/2;
        top: calc(50% - 2px);
      }
      select {
        appearance: none;
        padding: $spacer/2;
        border: 1px solid transparent;
        background-color: white;
        border-radius: 0;
        text-transform: uppercase;
        width: 100%;
        text-overflow: ellipsis;
        &:focus {
          outline: 0;
          border-color: $orange;
        }
      }
    }

    #select-date {
      width: 100%;
    }
  }
}

// Calendar view
.calendar-view {
  margin-bottom: 5vw;
  @media (max-width: $size-xs) {
    margin-bottom: 0;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: $spacer * 2;
    @media (max-width: $size-xs) {
      display: block;
    }
  }
  hr {
    flex: 1;
    margin: 0 2vw;
  }
  .timezone {
    @include font-size-map($font-size-sm);
    @media (max-width: $size-xs) {
      margin-bottom: $spacer;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    .download-calendar {
      @extend .btn-gradient-primary;
    }
    hr {
      margin-right: 0;
    }
  }
}
.calendar-columns {
  display: flex;
  margin-bottom: $spacer * 2;
  width: 100%;
  overflow: auto;
  @media (max-width: $size-xs) {
    margin-bottom: 0;
  }
  .column-header {
    text-align: center;
    padding: $spacer * 1.2;
  }
  .day-label,
  .date-label {
    display: block;
    font-size: 0.938vw;
    white-space: nowrap;
    @media (min-width: $container-width) {
      font-size: 18px;
    }
    @media (max-width: $size-sm) {
      font-size: 18px;
    }
  }
  .day-label {
    font-weight: $font-bold;
  }
  .column {
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    padding: 0;
    @media (max-width: $size-xs) {
      display: none;
      &.mobile-active {
        display: block;
        margin-bottom: $spacer;
      }
    }
  }
  .column-sessions {
    flex-grow: 1;
    position: relative;
    .session {
      position: absolute;
      width: 100%;
      padding: $spacer/2 $spacer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(#000, 0.2);
      cursor: pointer;
      * {
        pointer-events: none;
      }
      .title,
      .interval {
        font-weight: $font-medium;
        width: 100%;
        text-transform: uppercase;
        font-size: 0.677vw;

        @media (min-width: $container-width) {
          font-size: 13px;
        }
        @media (max-width: $size-xs) {
          font-size: 15px;
        }
      }
      &.added-to-calendar {
        .title,
        .interval {
          max-width: calc(100% - 20px - #{$spacer});
        }
        &::after {
          content: '';
          background-image: url(../images/checked.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          position: absolute;
          right: $spacer;
        }
      }
    }
  }
}

.session,
img {
  &.virtual-workshops {
    background: linear-gradient(-45deg, #6426c6 0%, #b4164c 100%);
  }

  &.general-session {
    background: linear-gradient(-45deg, #2343b8 0%, #00bc94 100%);
  }

  &.training-session {
    background: linear-gradient(-45deg, #f47f0c 0%, #d31924 100%);
  }

  &.lounges-open {
    background: linear-gradient(-45deg, #d31924 0%, #800a68 100%);
  }

  &.virtual-poster-session {
    background: linear-gradient(-45deg, #800a68 0%, #2343b8 100%);
  }

  &.think-tank-session {
    background: linear-gradient(-45deg, #ffd000 0%, #f47f0c 100%);
  }

  &.fireside-chat {
    background: linear-gradient(-45deg, #5e85eb 0%, #a741e6 100%);
  }
}

// Modal content
.modal-content {
  .topic {
    display: flex;
    align-items: flex-start;
    @media (max-width: $size-xs) {
      display: block;
    }

    .title {
      @include font-size-map($font-size-sm);
      text-transform: uppercase;
      font-weight: $font-bold;
      margin-bottom: $spacer/2;
      display: none;
    }
    .summary:not(:last-child) {
      margin-bottom: $spacer;
    }
    .add-to-calendar {
      @extend .btn-gradient-primary;
    }
    .topic-content {
      margin-left: $spacer * 2.3;
      @media (max-width: $size-xs) {
        margin-left: 0;
      }
    }
    &:not(:last-child) {
      margin-bottom: $spacer * 1.5;
      @media (max-width: $size-xs) {
        margin-bottom: $spacer * 3;
      }
    }
    figure {
      margin: 0;
      position: relative;
      display: flex;
      align-items: center;
      @media (max-width: $size-xs) {
        height: 188px;
        margin-bottom: $spacer;
      }
    }
    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    figcaption {
      position: absolute;
      font-weight: 300;
      padding: 0 $spacer;
      word-break: break-word;
      font-size: 20px;
      max-height: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
