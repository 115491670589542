.page-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  .container {
    position: relative;
    @media (max-width: $size-xs) {
      padding-left: $spacer/2;
      padding-right: $spacer/2;
    }
  }
}
.menu-trigger {
  width: 39px;
  height: 21px;
  display: block;
  background-image: url(../images/menu.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-top: 1.5vw;
  cursor: pointer;
  @media (max-width: $size-xs) {
    width: 27px;
    height: 16px;
    margin-top: 11px;
  }
}
.close-menu {
  width: 18px;
  height: 18px;
  background-image: url('../images/close-menu.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 23px;
  right: 23px;
  display: none;
  @media (max-width: $size-xs) {
    display: block;
  }
}
.menu-section {
  position: fixed;
  background-color: $body-background-color;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  padding: $spacer * 3;
  overflow: auto;
  display: none;
  @media (max-width: $size-xs) {
    text-align: center;
    padding: 17.15vw ($spacer * 2) ($spacer * 2);
  }
  .logo {
    max-width: 11.719vw;
    @media (max-width: $size-xs) {
      max-width: 40.821vw;
      margin-bottom: 17.15vw;
    }
  }
  .main-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 2.292vw;
    text-transform: uppercase;
    text-align: center;
    font-weight: $font-bold;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: $container-width) {
      font-size: 44px;
    }
    @media (max-width: $size-xs) {
      font-size: 7.246vw;
      max-width: 100%;
    }
    &--item {
      display: block;
      border-top: 1px solid rgba(white, 0.24);
      &:last-child {
        border-bottom: 1px solid rgba(white, 0.24);
      }
    }
    a {
      display: block;
      padding-top: 2.5vw;
      padding-bottom: 2.5vw;
      transition: color 0.3s;
      @media (min-width: $container-width) {
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
      }
      @media (min-width: $size-xs) {
        padding-top: 3vw;
        padding-bottom: 3vw;
      }
      &:hover {
        color: $orange;
      }
    }
  }
  .menu-section-artwork-1 {
    margin-top: 11.615vw;
  }

  &__open {
    display: block;
  }
}
